import React from "react";
import Layout from "../components/layout.js"
// import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { graphql } from "gatsby";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import grey from '@material-ui/core/colors/grey';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';

const getClasses = makeStyles(theme => ({
    blog: {
      background: grey[50],
    }
}));

const i18n = {
  'cn':{
    title: '博客',
    more: '阅读更多详情',
  },
  'en':{
    title: 'Blogs',
    more: 'Read More',
  }
}

function BlogItem(props){
  const { node, lang, href } = props;
  const { title, date } = node.frontmatter;
  const { excerpt} = node;
  const classes = getClasses();
  const texts = i18n[lang];
  return (
    <Box m={1}>
      <Card>
        <Box p={1} className={classes.blog}>
          <CardContent>
            <Typography variant='h5'>
              <Link href={href} underline='none' color='primary'>{title}</Link>
            </Typography>
            <Typography variant="subtitle1">
              {date}
            </Typography>
            <Typography component='div'>
              <div dangerouslySetInnerHTML={{__html: excerpt}} />
            </Typography>
          </CardContent>
          <CardActions>
              <Button href={href} color='secondary'>{texts.more}</Button>
          </CardActions>
        </Box>
      </Card>
    </Box>
  )
}

function getPageURL(root, index){
  if(0 === index){
    return root;
  }else{
    return root + index;
  }
}

const Blogs = ({ data, pageContext }) => {
  const defaultLang = 'cn';
  const lang = pageContext.lang || defaultLang;
  const { currentPage, totalPages } = pageContext;
  const texts = i18n[lang];
  let blogPath;
  if(lang === defaultLang){
    blogPath = '/blogs/';
  }else{
    blogPath = '/' + lang + '/blogs/';
  }

  var pages = [];
  if(0 !== currentPage){
    pages.push(
      <Link href={getPageURL(blogPath, currentPage - 1)}>
        <NavigateBefore/>
      </Link>
    );
  }
  for(var index = 0; index < totalPages; index++){
      if(index === currentPage){
        pages.push(
          <Typography component='div'>
            {index + 1}
          </Typography>
        )
      }else{
        pages.push(
          <Link href={getPageURL(blogPath, index)}>{index + 1}</Link>
        )
      }

  }
  if(currentPage < (totalPages -1 )){
    pages.push(
      <Link href={getPageURL(blogPath, currentPage + 1)}>
        <NavigateNext/>
      </Link>
    );
  }


  const blogs = data.allMarkdownRemark.edges;
  return (
    <Layout title={texts.title} lang={lang}>
      <Container maxWidth="md">
        <Box m={2}>
          <Grid container spacing={1}>
            {
              blogs.map( ({ node }) => {
                const { frontmatter } = node;
                let blogPath;
                if (defaultLang === lang){
                  blogPath = '/blogs/' + frontmatter.date;
                }else{
                  blogPath = '/' + lang + '/blogs/' + frontmatter.date;
                }
                return (
                  <Grid item xs={12} key={node.id}>
                    <BlogItem node={node} href={blogPath} lang={lang}/>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          {
            pages.map((link) => (
              <Box m={2}>
                {link}
              </Box>
            ))
          }
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query BlogsQuery($lang: String, $limit: Int!, $skip: Int!) {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/content/blogs/"}, frontmatter: {lang: {eq: $lang}}}, sort: {fields: [frontmatter___date], order: DESC},
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            lang
          }
          excerpt(truncate: true, format: HTML)
        }
      }
    }
  }
`;

export default Blogs;
